export const MODULE_CONFIG = {
	'log-expense': {
		roles: ['Support', 'Warehouse Manager', 'Warehouse Worker (Canada)', 'Lister', 'Streamer'],
	},
	'classify-products': {
		roles: ['Admin', 'Warehouse Worker (Canada)', 'Warehouse Manager', 'Lister', 'Streamer'],
	},
	'log-balance': {
		roles: ['Admin', 'Finance'],
	},
	'pick-order': {
		roles: ['Warehouse Manager', 'Warehouse Worker (Canada)', 'Warehouse Worker (TW)'],
	},
	'pack-order': {
		roles: ['Warehouse Manager', 'Warehouse Worker (Canada)'],
	},
	'bin-items': {
		roles: ['Warehouse Manager', 'Warehouse Worker (Canada)'],
	},
	'pick-order-legacy': {
		roles: ['Admin'],
	},
	'pack-order-legacy': {
		roles: ['Admin'],
	},
	'bin-items-legacy': {
		roles: ['Admin'],
	},
	'pack-box-legacy': {
		roles: ['Admin'],
	},
	'pack-order-v2': {
		roles: ['Warehouse Manager', 'Warehouse Worker (TW)'],
	},
	'bin-items-v2': {
		roles: ['Warehouse Manager', 'Warehouse Worker (TW)'],
	},
	'pack-box': {
		roles: ['Warehouse Manager', 'Warehouse Worker (Canada)'],
	},
	'pack-box-v2': {
		roles: ['Warehouse Manager', 'Warehouse Worker (Canada)'],
	},
	shipments: {
		roles: ['Warehouse Manager', 'Warehouse Worker (Canada)', 'Customs'],
	},
	support: {
		roles: ['Warehouse Manager', 'Warehouse Manager (Taiwan)', 'Support'],
	},
	'fulfillment-requests': {
		roles: [
			'Warehouse Worker (Canada)',
			'Warehouse Manager',
			'Fulfiller',
			'Fulfillment Management',
			'Taiwan Fulfillment Management',
		],
	},
	'gift-cards': {
		roles: ['Basic'],
	},
	'gift-cards-management': {
		roles: ['Admin'],
	},
	receiving: {
		roles: ['Warehouse Worker (Canada)', 'Warehouse Manager', 'Fulfiller', 'Fulfillment Management'],
	},
	'clear-bin': {
		roles: ['Warehouse Manager'],
	},
	'last-mile': {
		roles: ['Support', 'Warehouse Worker (TW)'],
	},
	'receive-box': {
		roles: ['Warehouse Manager', 'Warehouse Worker (TW)', 'Fulfillment Management', 'Taiwan Fulfillment Management'],
	},
	'inbound-shipments': {
		roles: ['Warehouse Manager', 'Warehouse Worker (TW)'],
	},
	'3pl-monitor': {
		roles: ['3PL Metrics'],
	},
	'taiwan-customs': {
		roles: ['Customs', 'Customs Payer (TW)'],
	},
	'customs-payments': {
		roles: ['Customs', 'Customs Payer (TW)'],
	},
	'find-item': {
		roles: [
			'Support',
			'Warehouse Manager',
			'Warehouse Worker (Canada)',
			'Warehouse Worker',
			'Warehouse Worker (TW)',
			'Warehouse Manager (TW)',
			'Streamer',
			'Lister',
		],
	},
	'find-order': {
		roles: [
			'Support',
			'Warehouse Manager',
			'Warehouse Worker (Canada)',
			'Warehouse Worker',
			'Warehouse Worker (TW)',
			'Warehouse Manager (TW)',
		],
	},
	'find-box': {
		roles: [
			'Warehouse Manager',
			'Warehouse Worker (Canada)',
			'Support',
			'Warehouse Worker (TW)',
			'Warehouse Manager (TW)',
			'Fulfillment Management',
			'Taiwan Fulfillment Management',
			'Customs',
		],
	},
	'announcement-timestamps': {
		roles: ['Support', 'Streamer'],
	},
	'stream-video-upload': {
		roles: ['Support', 'Streamer'],
	},
	'shopline-actions': {
		roles: ['Admin', 'Support', 'Streamer'],
	},
	listing: {
		roles: ['Lister', 'Streamer'],
	},
	buyer: {
		roles: ['Lister', 'Fulfiller', 'Admin'],
	},
	'add-inventory': {
		roles: ['Lister', 'Fulfiller', 'Admin'],
	},
	'line-push': {
		roles: ['Lister', 'Streamer'],
	},
	'livestream-captions': {
		roles: ['Streamer'],
	},
	'stream-companion': {
		roles: ['Lister', 'Streamer'],
	},
	'stream-listings': {
		roles: ['Lister', 'Streamer'],
	},
	'streamer-dashboard': {
		roles: ['Streamer'],
	},
	refulfill: {
		roles: ['Warehouse Manager', 'Refulfill'],
	},
	tracking: {
		roles: ['Warehouse Manager', 'Taiwan Fulfillment Management', 'Fulfillment Management', 'Admin'],
	},
	'email-receipts': {
		roles: ['Admin'],
	},
	'product-xml': {
		roles: ['Admin'],
	},
	'order-samples': {
		roles: ['Warehouse Manager'],
	},
	'user-samples': {
		roles: ['Admin', 'Streamer', 'Lister'],
	},
	'held-samples': {
		// -- Special Shortcut Module onf /modules, roles not actually used in the page loading
		roles: ['Warehouse Worker (Canada)', 'Lister', 'Streamer', 'Admin'],
	},
	'gs-held-samples': {
		// -- Special Shortcut Module onf /modules, roles not actually used in the page loading
		roles: ['Warehouse Worker (TW)', 'Warehouse Manager', 'Lister', 'Streamer', 'Admin'],
	},
	'executive-dashboard': {
		roles: ['Admin'],
	},
	'unit-economics-dashboard': {
		roles: ['Admin'],
	},
	'edit-bin': {
		roles: [
			'Warehouse Manager',
			'Warehouse Worker (TW)',
			'Warehouse Worker (Canada)',
			'Fulfiller',
			'Fulfillment Management',
			'Taiwan Fulfillment Management',
			'Lister',
			'Streamer',
			'Admin',
		],
	},
	'grocery-list': {
		roles: ['Admin', 'Warehouse Manager', 'Lister', 'External Fulfillment', 'Fulfillment Management'],
	},
	'inventory-management': {
		roles: ['Admin', 'Warehouse Manager', 'Inventory View'],
	},
	'inventory-report': {
		roles: ['Admin', 'Warehouse Manager'],
	},
	'syncing-tools': {
		roles: ['Admin', 'Support'],
	},
	'operations-dashboard': {
		roles: ['Warehouse Manager', 'Warehouse Manager (TW)', 'Admin'],
	},
	accounting: {
		roles: ['Admin', 'Accounting'],
	},
	kpis: {
		roles: ['Admin', 'Metrics'],
	},
	'manage-users': {
		roles: ['Admin', 'Hiring'],
	},
	'manage-brands': {
		roles: ['Admin', 'Streamer', 'Manage Brands'],
	},
	'manage-colors': {
		roles: ['Admin', 'Streamer'],
	},
	'manage-sizes': {
		roles: ['Admin'],
	},
	'manage-caption-styles': {
		roles: ['Admin', 'Streamer'],
	},
	'admin-tools': {
		roles: ['Admin', 'Support'],
	},
	'download-supabase-file': {
		roles: ['Admin'],
	},
	'manage-user-samples': {
		roles: [
			'Admin',
			'Streamer',
			'Lister',
			'Warehouse Manager',
			'Fulfiller',
			'Fulfillment Management',
			'Taiwan Fulfillment Management',
		],
	},
	dev: {
		roles: ['Admin', 'Dev'],
	},
};
